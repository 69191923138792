/* css/style.css */

/* Estilos por defecto para iframe abierto */
.rbc-iframe-open {
    position: fixed;
    bottom: 0px;
    right: 0px;
    // width: 400px;
    width: 410px;
    height: 625px;
    border: none;
    z-index: 1000;
    transition: height 0.3s ease;
}


/* Estilos por defecto para iframe cerrado */
.rbc-iframe-close {
    position: fixed;
    bottom: 0px;
    right: 0px;
    width: 100px;
    height: 83px;
    border: none;
    z-index: 1000;
    /* Lo importante es que el usuario vea el cierre del mensaje de manera correcta
    No importa que el iframe tarde algo más */
    transition: width 1s ease;
    /* transition: height 1s ease; */
}

/* Estilos cuando el iframe está mostrando un mensaje tipo 'slide' */
.rbc-iframe-slide-close {

    position: fixed;
    bottom: 0px;
    right: 0px;
    /* width: 400px; */
    height: 83px !important;
    border: none;
    z-index: 1000;


}

/* Estilos cuando el iframe está mostrando un mensaje tipo 'bubble' */
.rbc-iframe-bubble-close {
    /* Añade aquí los estilos específicos para cuando el mensaje es de tipo 'bubble' */

    position: fixed;
    bottom: 0px;
    right: 0px;
    width: 263px !important;
    /* height: 250px; */
    border: none;

    z-index: 1000;
}



/* rbc-iframe-slide-open
rbc-iframe-bubble-open */

/* Media queries para adaptarse a los puntos de interrupción de Tailwind en el CSS */
@media (max-width: 450px) and (max-height: 800px) {

    .rbc-iframe-close {
        width: 100vh;
        /* Ancho para pro12 y pro14max */
    }

    .rbc-iframe-open {
        width: 100vh;
        /* Ancho por defecto */
        height: 100vh;
        /* Altura por defecto */
        bottom: 0px;
        right: 0px;
    }
}


/* Media queries para adaptarse a los puntos de interrupción de Tailwind en el CSS */
/* @media (max-width: 412px) {

    .rbc-iframe-close {
        width: 390px; 
    }

    .rbc-iframe-open { 
        width: 410px;
        height: 100vh; 
        bottom: 0px;
        right: 0px;
    }
} */


/* Estilos para iPhone X (375px x 812px) */
@media (max-width: 375px) {
    .rbc-iframe-open {
        width: 100%;
        /* Ocupa todo el ancho de la pantalla */
        height: 100vh;
        /* Altura ajustada a la altura de la pantalla */
    }
}

/* Estilos para Samsung Galaxy S8/S9/S10 (360px x 740px) */
@media (max-width: 360px) {
    .rbc-iframe-open {
        width: 100%;
        height: 100vh;
    }
}

/* Estilos para iPhone 6/7/8 Plus (414px x 736px) */
@media (max-width: 414px) {
    .rbc-iframe-open {
        width: 100%;
        height: 100vh;
    }
}

/* Estilos para pantallas más pequeñas (por ejemplo, iPhone SE) */
@media (max-width: 320px) {
    .rbc-iframe-open {
        width: 100%;
        height: 100vh;
    }
}

/* ... [puedes agregar más consultas de medios para otros dispositivos si es necesario] ... */

/* Estilos para pantallas de tamaño medio */
@media (min-width: 400px) and (max-width: 599px) {
    .rbc-iframe-open {
        // width: 400px;
        width: 410px;
        /* Ancho ajustado para pantallas medianas */
        height: 100vh;
    }
}

/* Estilos para pantallas más grandes */
@media (min-width: 600px) {
    .rbc-iframe-open {
        // width: 400px;
        width: 410px;

        /* Ancho para pantallas grandes */
        height: 625px;
    }
}