.ripple-button {
  /* width: "100%"; */
  position: relative;

  overflow: hidden;



  /* background-color: #4e9e62; */
  color: white;
  /* padding: 10px 20px; */
  /* border-box: box-sizing; */
  box-sizing: border-box;
  border: none;
  /* border-radius: 8px; */
  cursor: pointer;
  outline: none;

  /* height: 40px; */

  display: flex;
  align-items: center;
  /* justify-content: center; */


  display: inline-block;
  vertical-align: top;
}

.ripple {
  position: absolute;
  border-radius: 50%;
  transform: scale(0);
  animation: ripple-animation 600ms linear;
  background-color: rgba(255, 255, 255, 0.3);
}

@keyframes ripple-animation {
  to {
    transform: scale(4);
    opacity: 0;
  }
}