// Colores proporcionados
$input-bg-color-default: #fff;

$input-border-color: #3d4f65;

$input-bg-color-active: #fefefe;
$input-text-color: #333;
// $input-border-color: transparent;
$input-focus-border-color: transparent;
$input-selected-bg-color: #e9ecef;
$input-box-shadow-color: rgba(0, 123, 255, 0.25);

.cmulti-select-light {

    background-color: $input-bg-color-default;
    border: 1px solid $input-border-color;
    min-height: 40px; 
    display: flex;
    align-items: center;
    flex-wrap: wrap;


    &:focus {
        background-color: $input-bg-color-default;
        border-color: $input-border-color;
        box-shadow: 0 0 0 0.2rem $input-box-shadow-color;

    }

    // No existe la pseudoclase :blur en CSS, si necesitas un estilo cuando se pierde el foco, deberías manejarlo con JavaScript
    // Asegúrate de manejar el estado "blur" con una clase o evento en JavaScript si es necesario

    div,
    span,
    input {
        color: $input-text-color;
    }

    .form-multi-select-search {
        background-color: $input-bg-color-default;
        border: none;
        padding: 5px 10px;
        border-radius: 5px;
        color: $input-text-color;

        &::placeholder {
            color: $input-text-color;
        }

    }

    .dropdown-menu {
        background-color: $input-bg-color-active;
    }

    &.form-multi-select {
        padding: 0px;
    }

    &.form-multi-select.show {
        background-color: $input-bg-color-default;

        // background-color: $input-bg-color-default;
        // box-shadow: 0 0 0 0.25rem $input-box-shadow-color;
        box-shadow: 0 0 8px $input-box-shadow-color;


        border: 1px solid $input-border-color;


    }

    .virtual-scroller-content {
        background-color: $input-bg-color-active;
    }

    .form-multi-select-option {
        background-color: $input-bg-color-active;
        color: $input-text-color;
        border-top: 1px solid $input-selected-bg-color;
        padding: 5px 10px;
        border-radius: 5px;

        &.form-multi-selected {
            background-color: $input-selected-bg-color;
        }

        &:first-child {
            border-top: none;
        }
    }

    .virtual-scroller {
        &::-webkit-scrollbar {
            width: 6px;
            background-color: $input-bg-color-default;
            padding: 4px;
        }

        &::-webkit-scrollbar-thumb {
            border-radius: 5px;
            background-color: $input-text-color;
        }

        &::-webkit-scrollbar-track {
            border-radius: 5px;
            background-color: $input-bg-color-default;
        }
    }
}