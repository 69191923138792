// Colores proporcionados
$input-bg-color-default: #fff;
$input-border-color: #3d4f65;
$input-bg-color-active: #fefefe;
$input-text-color: #333;
$input-focus-border-color: #80bdff; // Ajusta este color para cambiar el borde de foco
$input-selected-bg-color: #e9ecef;
$input-box-shadow-color: rgba(0, 123, 255, 0.25);

.date-picker-light {
    .input-group {
        box-shadow: none !important; // Elimina la sombra del borde

        .form-control {
            background-color: $input-bg-color-default;
            border: 1px solid $input-border-color;
            color: $input-text-color;

            &:focus {
                border-color: $input-focus-border-color;
                box-shadow: 0 0 0 2px $input-box-shadow-color !important; // Asegura que el borde de foco esté visible
            }
        }

        .input-group-text {
            background-color: $input-bg-color-default;
            border: 1px solid $input-border-color;
            border-left: 0;

            .picker-input-group-icon {
                color: $input-text-color;
            }
        }
    }
}