@import "@coreui/coreui/scss/functions";
@import "@coreui/coreui/scss/variables";
@import "@coreui/coreui/scss/maps";
@import "@coreui/coreui/scss/mixins";
@import "@coreui/coreui/scss/root";

@import "@coreui/coreui/scss/variables";

// fonts
@import "./fonts/fonts.scss";

@import "variables";



// @import "@coreui/coreui/scss/variables";

$enable-ltr: true;
/* stylelint-disable-line scss/dollar-variable-default */
$enable-rtl: true;
/* stylelint-disable-line scss/dollar-variable-default */

// Import styles
@import "@coreui/coreui-pro/scss/coreui";
@import "@coreui/coreui-pro/scss/themes/dark";

@import "layout";


@import "custom";


body {
    font-family: 'Roboto', sans-serif;
}

:root {
    --scrollbar-track-color: #f1f1f1;
    --scrollbar-thumb-color: #888;

}


// .c-sidebar 
.sidebar-lateral {
    background-color: $principalColor;
}

// Toast estilos
@import 'react-toastify/dist/ReactToastify.css';

// @import "./common.scss";

// Drag & drop
@import "../../../app/components/drag-drop/styles/DragDrop.scss";
@import "../../../app/components/drag-drop-simple/styles/DragDropSimple.scss";
// @import "./src/app/./src/app/./src/app/app/components/warnNoCompany/warnNoCompany-style.scss";

// loader
@import "../../../app/components/loader/loader.scss";

// Components
@import "./components/appsidebarnav.scss";
@import "./components/filterchip.scss";
@import "./components/toast.scss";

// Components Light React Core UI Pro
@import "./components/light/inputCustomLight.scss";
@import "./components/light/cmultiselectCustomLight.scss";
@import "./components/light/cinputColorCustomLight.scss";




// pages
@import "./pages/login.scss";
@import "./pages/conversation-page.scss";
@import "./components/cform-texarea-custom.scss";
@import "./components/my-rich-text-area.scss";
@import "./components/iframe-bot.scss";
@import "./components/light/cDateRangePickerCustomLight.scss";


// @import "@coreui/coreui/scss/maps";


$pepe: #f3f3f3;

.table-bg-notification {
    // color: "white";
    background-color: $pepe;
}

.rdp {
    --rdp-cell-size: 40px;
    --rdp-caption-font-size: 18px;
    --rdp-accent-color: #0000ff;
    --rdp-background-color: #e7edff;
    --rdp-accent-color-dark: #3003e1;
    --rdp-background-color-dark: #180270;
    --rdp-outline: 2px solid var(--rdp-accent-color);
    --rdp-outline-selected: 3px solid var(--rdp-accent-color);
    --rdp-selected-color: #880000;
}

.recharts-pie path:focus {
    outline: none;
}

.input-autofill {
    background-color: transparent !important;
    /* Fuerza el fondo a ser transparente */
    color: black !important;
    /* Puedes cambiar el color del texto si es necesario */
}

/* Asegúrate de usar un selector de alta especificidad para sobreescribir los estilos de autofill del navegador */
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
    transition: background-color 5000s ease-in-out 0s;
    /* Retrasa el cambio de color de fondo */
    -webkit-text-fill-color: black !important;
    /* Cambia el color del texto autofill de Chrome/Safari */
}

/* DayPickerCustomStyles.css */


/* Añade estilos adicionales aquí si es necesario */