// Definición de colores y estilos base
$input-bg-color: #fff;
$input-text-color: #333;
$input-border-color: #3d4f65;

$input-focus-border-color: #0056b3;
$input-box-shadow-color: rgba(0, 123, 255, 0.25);

// Estilos personalizados para el input
.input-light {
    background-color: $input-bg-color;
    color: $input-text-color;
    border: 1px solid $input-border-color;
    border-radius: 0.5rem;
    padding: 0.5rem 1rem;
    box-shadow: 0;
    transition: border-color 0.3s ease-in-out, box-shadow 0.3s ease-in-out;

    &:focus {
        border-color: $input-focus-border-color;
        box-shadow: 0 0 8px $input-box-shadow-color;
        outline: none; // Elimina el outline predeterminado para enfocar en los estilos personalizados
    }
}