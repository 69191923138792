.sidebar.sidebar-fixed {
    // z-index: 2010 !important;
}

.sidebar-nav {
    scrollbar-width: thin;
    /* Para Firefox */
    scrollbar-color: transparent $tertiaryColor;
    

    &:hover {
        scrollbar-color: $quaternaryColor $tertiaryColor;
    transition: scrollbar-color 0.15s ease-in-out;


    }

    

    /* Para Firefox */

    /* Para Webkit (Chrome, Edge, Safari) */
    &::-webkit-scrollbar {
        width: 3px;
    }

    &::-webkit-scrollbar-track {
        background: var(--scrollbar-track-color);
    }

    &::-webkit-scrollbar-thumb {
        background-color: var(--scrollbar-thumb-color);
        border-radius: 6px;
        border: 3px solid var(--scrollbar-track-color);
    }
}

.sidebar-nav .nav-link.active {
    position: relative; // Esto es necesario para posicionar correctamente el pseudo-elemento
    color: var(--cui-sidebar-nav-link-active-color);




    // No aplicamos ningún color de fondo aquí para que el pseudo-elemento pueda manejarlo
    // background: var(--cui-sidebar-nav-link-active-bg);

    // Añadir pseudo-elemento para el fondo parcial

    //   content: ''; // Necesario para que se muestre el pseudo-elemento
    //   position: absolute; // Posicionamiento absoluto respecto al padre relativo
    //   top: 0;
    //   left: 0;
    //   bottom: 0;
    //   width: 4px; // Ancho del área activa, ajusta según sea necesario
    //   background: var(--cui-sidebar-nav-link-active-bg); // Color de fondo para el área activa
    //   border-top-right-radius: 4px; // Añade bordes redondeados si es necesario
    //   border-bottom-right-radius: 4px;

}