// $principalColor: #3d4f65;
// $secondaryColor: #f08a3b;
// $tertiaryColor: #1d1d26;
// $quaternaryColor: #323343;

.login {
  .bg-login {
    // background-color: rgb(249, 249, 249);
 
    background-color: whitesmoke;


    // @include media-breakpoint-down(md) {
    //   padding: 0px;
    //   background-image: url("../../img/login-BKG.png");
    //   background-repeat: no-repeat;
    //   background-size: cover;
    //   background-position: center center;
    //   height: 100%;
    // }
  }
  .form-wp {
    font-size: 16px;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    .ng-logo {
      width: 100%;
      max-width: 320px;
      margin-bottom: 25px;
      img {
        width: 185px;
      }
    }

    // @include media-breakpoint-down(md) {
    // }

    form {
      // width: 370px;
      // background-color: yellow;
      // box-shadow: 0 30px 20px 0 rgba(0, 0, 0, 0.1);
      // position: absolute;
      // right: -185px;
      z-index: 99;
      /*background-image: linear-gradient(to bottom, #18315a, #923248 35%, #ea333b);*/
      // padding: 0 0 0 5px;

      .forgot-passw a {
        font-size: 12px;
        color: #a2a2a2;
        margin-top: 20px;
        display: block;
      }

      // @include media-breakpoint-down(md) {
      //   right: auto;
      //   position: relative;
      // }

      // @include media-breakpoint-down(sm) {
      //   width: 90%;
      // }
    }
    .form-group {
      margin-top: 20px;
      margin-bottom: 0px !important;
    }
    .title {
      font-size: crem(30px);
      font-weight: 600;
    }
    .input-icon {
      display: flex;
      align-items: center;
      input {
        border-radius: 0;

        background-color: rgb(255, 255, 255) !important;
        background: 0 0;
        border: none;
        outline: 0;
        padding: 0;
        margin: 0;
        max-width: 100%;
        vertical-align: bottom;

        width: 270px;
        height: 30px;
        padding: 18px 0px 18px;
        // border-bottom: 1px solid #e8e8e8;
        outline: 1px solid #e8e8e8;

        // border-inline: 1px solid #e8e8e8;
        border-end-end-radius: 5px;
        border-start-end-radius: 5px;

        // @include media-breakpoint-down(sm) {
        //   width: 100%;
        // }
      }

      // input::placeholder {
      //   color: #a2a2a2;
      // }

      // input:-internal-autofill-selected {
      //   background-color: rgb(255, 255, 255) !important;
      //   color: -internal-light-dark(black, white) !important;
      // }

      label {
        height: crem(40px);
        border: 1px solid #ced4da;
        border-left: 0;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-bottom: 0;
        padding-right: crem(12px);
        padding-left: crem(12px);
      }
      i {
        font-size: 20px;
        opacity: 0.7;
      }
    }
    .btn-access {
      margin-top: 50px;
    }
  }
  .login-right {
    height: 100vh;
    // background-color: rgb(236, 236, 236);
    background-color: rgb(249, 249, 249);

    // @include media-breakpoint-down(md) {
    //   display: none;
    // }
    .login-right_image {
      background-image: url("../../../../../public/images/login-BKG.jpg");

      background-repeat: no-repeat;
      background-size: cover;
      background-position: center center;
      height: 100%;
      // @include media-breakpoint-up(xl) {
      //   background-size: cover;
      // }
    }
  }
}

.bkg-gradient {
  padding: 45px 50px;
  background-color: white;
}
