@mixin transition($property) {
    transition: $property 0.2s ease-in-out;
}


// Es necesario porque por defecto el señor de la librería le ha puesto un padding más grande que mi cabeza
.vr {
    padding-right: 0px !important;
    padding-left: 0px !important;
}

// Cambia de manera global el el color del sidebar
.sidebar {
    // ORIGINAL NIMO
    // --cui-sidebar-bg: #0a1215 !important;
    // COLOR ALGO MÁS CLARO
    --cui-sidebar-bg: #1d1d26 !important;

}

// Cambia de manera global el color de los botones marcados de la paginación
.pagination {
    --bs-pagination-active-bg: #101d23 !important;
    --bs-pagination-active-border-color: #101d23 !important;

    // Páginas no seleccionadas
    --bs-pagination-color: #101d23 !important;
    // hover
    --bs-pagination-hover-color: #616161 !important;
}

.calendar {
    // --cui-calendar-cell-selected-bg: #101d23 !important;
    // --cui-calendar-cell-range-bg: rgba(16, 29, 35, 0.1) !important;
    // // hover
    // --cui-calendar-cell-hover-bg: #c3c3c3 !important;
    // --cui-calendar-cell-range-hover-bg: #c3c3c325 !important;

    // COMIENZO
    --cui-calendar-cell-selected-bg: #3d4f65 !important;
    --cui-calendar-cell-range-bg: #a4afbd !important;
    // // hover
    --cui-calendar-cell-hover-bg: #677a90 !important;
    --cui-calendar-cell-range-hover-bg: #c3c3c325 !important;

}

// Checkbox de los multiselect
.form-multi-select {
    --cui-form-multi-select-option-selected-indicator-bg: #101d23 !important;
    --cui-form-multi-select-option-selected-indicator-border-color: #101d23 !important;
}

// Checkbox de las tablas (Afecta al check principal) Estos checks son generados por mí
.form-check-input:checked {
    background-color: #101d23 !important;
    border-color: #101d23 !important;
}

// Checkbox de las tablas (Afecta al check principal) Estos checks son generados por mí
.form-check-input:indeterminate {
    background-color: #101d23 !important;
    border-color: #101d23 !important;
}


// :root {
//     --cui-form-multi-select-focus-border-color: green !important;
// }

// .dropdown.show.picker.form-multi-select {
//     --cui-form-multi-select-focus-border-color: #f08a3b !important;  /* o cualquier otro color que desees */
// }

// .form-multi-select.show {
//     box-shadow: 0 0 0 0.25rem rgb(59, 201, 3);
// }

.body {
    // font-family: "Raleway", sans-serif !important;
    // background-color: $tertiaryColor;
    background-color: $principalColor;
}

.footer {
    // font-family: "Raleway", sans-serif !important;

    // background-color: $tertiaryColor;
    background-color: $principalColor;

    color: black;
    border-top: 1px solid $principalColor;
    // border-top: 1px solid $tertiaryColor;
}

.header {
    // font-family: "Raleway", sans-serif !important;

    // background-color: $tertiaryColor;
    // background-color: $principalColor;
    background-color: rgba($principalColor, 0.8);
    color: white;
    margin-bottom: 0px;

}
// base-Popper-root MuiPickersPopper-root css-1anqmj6-MuiPopper-root-MuiPickersPopper-root
.header.header-sticky {
    backdrop-filter: blur(2px);
    box-shadow: none;
    // z-index: 2010;
}

.wrapper {
    background-color: pink;
}

.cHeaderWithColor {
    border-bottom: 20px solid red;
    // margin-bottom: 0;
}


.no-select {
    user-select: none;
    /* Standard syntax */
    -webkit-user-select: none;
    /* Safari */
    -moz-user-select: none;
    /* Firefox */
    -ms-user-select: none;
    /* Internet Explorer/Edge */
}

/* Ocultar flechas en input de número para Chrome, Safari y Opera */
.input-number::-webkit-outer-spin-button,
.input-number::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

/* Ocultar flechas en input de número para Firefox */
.input-number[type=number] {
    -moz-appearance: textfield;
}


.hide-scrollbar {
    /* Para Internet Explorer, Edge */
    -ms-overflow-style: none;

    /* Para Firefox */
    scrollbar-width: none;

    /* Para Chrome, Safari, Opera */
    &::-webkit-scrollbar {
        display: none;
    }
}

.thin-scrollbar {
    scrollbar-width: thin;
    /* Para Firefox */
    scrollbar-color: $tertiaryColor transparent;


    // &:hover {
    //     scrollbar-color: $quaternaryColor $tertiaryColor;
    //     transition: scrollbar-color 0.15s ease-in-out;

    // }
}

.shadow-top {
    box-shadow: 0 -2px 6px -1px rgba(0, 0, 0, 0.1), 0 -2px 4px -1px rgba(0, 0, 0, 0.06);
}