.emoji-picker-container {
    position: absolute;
    top: 40px;
    right: -130px;
    /* Alineación con el icono de emoji */
    z-index: 2;
    /* Asegúrate de que el Picker esté sobre otros elementos */
    transform: translateX(-50%);
    /* Centrar el Picker horizontalmente respecto al icono */
}




.emoji-icon {
    position: absolute;
    bottom: 28px;
    /* Ajusta según la altura de tu CFormTextarea */
    right: 10px;
    /* Ajusta según necesites */
    cursor: pointer;
    z-index: 3;
    /* El z-index debe ser mayor que el del Picker para que no se mueva detrás de él */
}


.input-light {
    position: relative;
    padding-right: 40px;
    /* Espacio adicional para el ícono de emoji */
}

.relative {
    position: relative;
    /* Esto asegura que el Picker se posicione en relación a este contenedor */
}