.react-quill .ql-editor {
    font-size: 18px !important; /* Establece el tamaño de la fuente que prefieras */

}


.ql-container {
    box-sizing: border-box;
    font-family: Helvetica, Arial, sans-serif;
    /* font-size: 13px; */
    font-size: 16px;
    height: 100%;
    margin: 0px;
    position: relative;
  }

  // .ql-editor .ql-size-small {
  //   /* font-size: 0.75em; */
  //   font-size: 10px;
  // }

  // .ql-editor .ql-size-large {
  //   /* font-size: 1.5em; */
  //   font-size: 14px;
  // }

  // .ql-editor .ql-size-huge {
  //   /* font-size: 2.5em; */
  //   font-size: 16px;
  // }

  // .ql-snow .ql-picker.ql-size .ql-picker-label::before,
  // .ql-snow .ql-picker.ql-size .ql-picker-item::before {
  //   /* content: 'Normal'; */
  //   content: '12px';
  // }

  // .ql-snow .ql-picker.ql-size .ql-picker-label[data-value=small]::before,
  // .ql-snow .ql-picker.ql-size .ql-picker-item[data-value=small]::before {
  //   /* content: 'Small'; */
  //   content: '10px';
  // }

  // .ql-snow .ql-picker.ql-size .ql-picker-label[data-value=large]::before,
  // .ql-snow .ql-picker.ql-size .ql-picker-item[data-value=large]::before {
  //   /* content: 'Large'; */
  //   content: '14px';
  // }

  // .ql-snow .ql-picker.ql-size .ql-picker-label[data-value=huge]::before,
  // .ql-snow .ql-picker.ql-size .ql-picker-item[data-value=huge]::before {
  //   /* content: 'Huge'; */
  //   content: '16px';
  // }

  // .ql-snow .ql-picker.ql-size .ql-picker-item[data-value=small]::before {
  //   font-size: 10px;
  // }

  // .ql-snow .ql-picker.ql-size .ql-picker-item[data-value=large]::before {
  //   /* font-size: 18px; */
  //   font-size: 14px;
  // }

  // .ql-snow .ql-picker.ql-size .ql-picker-item[data-value=huge]::before {
  //   /* font-size: 32px; */
  //   font-size: 16px;
  // }