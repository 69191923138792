.applied-filters {
    display: flex;
    flex-wrap: wrap;
    gap: 8px;
    min-height: 34.5px;
    /* Espacio entre chips */
}

.chip {
    font-size: 14px;
    display: flex;
    align-items: center;
    padding: 3px 6px 3px 14px;
    // background-color: #e0e0e0;
    background-color: #e8e8e8;

    // background-color: ;
    // border: #e0e0e0 solid 1px;
    border-radius: 17px;
    gap: 4px;

    .chip-title {
        
        font-weight: 500;
        color: #000000;
    }

    .chip-value {
        font-weight: 400;
        color: #000000;
        margin-left: 2px;
    }
    /* Espacio entre el texto y el botón de cerrar */
}

.chip button {
    border: none;
    background-color: transparent;
    cursor: pointer;
    font-size: 16px;
}