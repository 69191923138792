.loader-item {
    // position: absolute;
    position: fixed;
    z-index: 3000;
    // display: flex;
    display: none;

    justify-content: center;
    align-items: center;
    height: 100vh;
    width: 100vw;
    

    // background-color: rgba(167, 175, 107, 0.578);
    // background-color: rgba(255, 255, 255, 0.75);

}
