.toast-custom {
    border: 4px solid #ccc;
    /* Cambia #ccc por el color de borde que prefieras */
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    /* Ajusta la sombra según tus preferencias */
    border-radius: 4px;
    /* Añade un borde redondeado si lo deseas */
}

.toast-success-container {
    color: #000 !important;
    border-radius: 8px !important;
    background: #FFFFFF !important;
    border: 1px solid #34A853 !important;
    box-shadow: 0px 4px 10px rgba(248, 175, 175, 0.1) !important;
    max-height: 40px !important;
    padding: 0px !important;
}

.toast-success-container-after {
    overflow: hidden;
    position: relative;
}

.toast-success-container-after::after {
    top: 0;
    left: 0;
    content: '';
    width: 7px;
    height: 100%;
    position: absolute;
    display: inline-block;
    background-color: #34A853;
}


// .Toastify__toast--error {
//     border: 1px solid #EB5757;
//     border-radius: 50px !important;
//     background: #FAE1E2 !important;
// }

// .Toastify__toast--error::after {
//     //   content : url('../assets/images/svg/closeToast.svg'); // Your svg Path
//     position: absolute;
//     color: #333333;
//     font-size: 15px;
//     font-weight: 700;
//     left: 265px;
//     padding-top: 14px !important;
// }

// .Toastify__toast--error::before {
//     //   content: url("../assets/images/svg/errorToast.svg");// Your svg Path
//     position: relative;
//     z-index: 100000;
//     left: 12px;
//     top: 6px;
// }

// .Toastify__toast--success {
//     border: 1px solid #3A9EA5 !important;
//     border-radius: 50px !important;
//     background: #F0F9FA !important;
// }

// .Toastify__toast--success::before {
//     //   content: url("../assets/images/svg/successToast.svg");// Your svg Path
//     position: relative;
//     z-index: 100000;
//     left: 12px;
//     top: 6px;
// }

// .Toastify__toast--success::after {
//     //   content : url('../assets/images/svg/closeToast.svg');// Your svg Path
//     position: absolute;
//     color: #333333;
//     font-size: 15px;
//     font-weight: 700;
//     left: 265px;
//     padding-top: 14px !important;
// }

// .Toastify__toast--warning {
//     border: 1px solid #E78326 !important;
//     border-radius: 50px !important;
//     background: #FADFC5 !important;
// }

// .Toastify__toast--warning::before {
//     //   content: url("../assets/images/svg/warnToast.svg");// Your svg Path
//     position: relative;
//     z-index: 100000;
//     left: 12px;
//     top: 6px;
// }

// .Toastify__toast--warning::after {
//     //   content : url('../assets/images/svg/closeToast.svg'); // Your svg Path
//     position: absolute;
//     color: #E78326;
//     font-size: 15px;
//     font-weight: 700;
//     left: 265px;
//     padding-top: 14px !important;
// }

// .Toastify__toast-body {
//     color: #444C63;
//     font-size: 16px;
//     padding-left: 20px;
//     line-height: 20px;
//     padding: 0px;
//     padding-top: 25px;
//     width: 100%;
//     font-weight: 400;
//     margin-left: 25px !important;
// }

// .Toastify__toast>button>svg {
//     display: none;
// }